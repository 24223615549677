import { PanelPlugin } from '@grafana/data';
import { SliderRangePanelOptions } from './types';
import { SliderRangePanel } from './SliderRangePanel';

export const plugin = new PanelPlugin<SliderRangePanelOptions>(SliderRangePanel).setPanelOptions((builder) =>
  builder
    .addTextInput({
      path: 'caption',
      name: 'Caption',
      description: 'Slider Caption',
      defaultValue: 'Speed [knot]',
    })
    .addTextInput({
      path: 'variable',
      name: 'Variable',
      description: 'Filter on',
      defaultValue: 'speed',
    })
    .addBooleanSwitch({
      path: 'horizontal',
      name: 'Horizontal',
      description: 'Horizontal orientation',
      defaultValue: true,
    })
    .addNumberInput({
      path: 'min',
      name: 'Minimum value',
      defaultValue: 1,
    })
    .addNumberInput({
      path: 'max',
      name: 'Maximum value',
      defaultValue: 50,
    })
    .addColorPicker({
      path: 'trackColor',
      name: 'Track color',
      description: 'Color of the track'
    })
    .addColorPicker({
      path: 'handleColor',
      name: 'Handle color',
      description: 'Color of the handles'
    })
);

import React from 'react';
import { GrafanaTheme2, PanelProps } from '@grafana/data';
import { SliderRangePanelOptions, ExtendedVariableModel } from 'types';
import { Label, RangeSlider, useStyles2 } from '@grafana/ui';
import { getLocationSrv, getTemplateSrv } from '@grafana/runtime';
import _ from 'lodash';
import { cx, css } from '@emotion/css';

interface Props extends PanelProps<SliderRangePanelOptions> { }

export function onChange(key: string, minMax: number[]) {
  const query = {
    [`var-${key}_min`]: minMax[0],
    [`var-${key}_max`]: minMax[1],
  };

  getLocationSrv().update({
    partial: true,
    replace: true,
    query,
  });
}

export function getVal(key: String, fallback: number) {
  const vars = getTemplateSrv().getVariables() as ExtendedVariableModel[];
  const val = vars && vars.find((v) => v.name === key);
  if (val && val.current) {
    return +val.current.value;
  } else {
    return fallback;
  }
}

export const SliderRangePanel: React.FC<Props> = (props) => {
  const { series } = props.data;
  const { handleColor, trackColor, variable, min: _min, max: _max, horizontal, caption } = props.options;
  const style = useStyles2(getStyles(handleColor, trackColor));
  const min = Math.max(getVal(variable + '_min', _min), _min);
  const max = Math.min(getVal(variable + '_max', _max), _max);
  const unitSerie = series.find((serie: any) => serie.refId === 'unit');
  const unit = unitSerie?.fields.find((field: any) => field.name === `${variable}_unit`)?.values.toArray()[0];
  const unitFactor = unitSerie?.fields.find((field: any) => field.name === `${variable}_unit_factor`)?.values.toArray()[0] ?? 1;
  const unitOffset = unitSerie?.fields.find((field: any) => field.name === `${variable}_unit_offset`)?.values.toArray()[0] ?? 0;

  const toUnitValue = (baseValue: number) => {
    // Enforce that only whole/round values are displayed.
    return Math.round(baseValue * unitFactor + unitOffset);
  }
  const toBaseValue = (value: number) => {
    return (value - unitOffset) / unitFactor;
  }
    // Debounce onChange to avoid too many updates
  const debouncedOnChange = _.debounce(onChange, 300);

  return (
    <div className={cx(style.box)}>
      <Label>{(unit ? `${caption} [${unit}]` : caption)}</Label>
      <div className={cx(style.wrapper, horizontal ? style.horizontalContent : style.verticalContent)}>
        <RangeSlider
          value={[toUnitValue(min), toUnitValue(max)]}
          min={toUnitValue(_min)}
          max={toUnitValue(_max)}
          orientation={horizontal ? 'horizontal' : 'vertical'}
          onChange={(change) => debouncedOnChange(variable, change.map(value => toBaseValue(value)))}
        />
      </div>
    </div>
  );
};

const getStyles = (handleColor?: string, trackColor?: string) => (theme: GrafanaTheme2) => ({
  wrapper: css`
      width: 100%;
      .rc-slider-track {
        background-color: ${trackColor ? theme.visualization.getColorByName(trackColor) : theme.colors.primary.main} !important;
      }
      .rc-slider-handle {
        background-color: ${handleColor ? theme.visualization.getColorByName(handleColor) : theme.colors.primary.main} !important;
      }
    `,
  box: css`
      display: flex;
      flex-flow: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
      align-items: normal;
      height: 100%;
      max-width: 100%;
      margin-bottom: 14px;
      margin-right: 14px;
    `,
  verticalContent: css`
      flex: 1 1 auto;
      margin-bottom: 14px;
      margin-right: 14px;
      `,
  horizontalContent: css`
      flex: 1 1 auto;
      margin-top: 14px;
      margin-right: 14px;
      `,
});
